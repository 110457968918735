import React from "react";
import { AnalyticsRoute } from "./utils";
import { GroupLandingPage } from "tuteria-frontend-components/src/pages/ExternalPages/GroupLandingPage";
// const SecondaryLinkButton = getLinkButton("secondary");
// const  = getLinkButton("primary");
class GroupPage extends React.Component {
  onClassSelected = (history, url) => data => {
    this.props.updateState({ selectedDetails: data });
    if (typeof window !== "undefined") {
      window.sessionStorage.setItem("selectedDetails", JSON.stringify(data));
    }
    history.push(`${url}/${data.url}/info`);
  };
  render() {
    let { basePath, groupLessons = [] } = this.props;
    let locations = groupLessons;
    console.log(locations);
    return (
      <React.Fragment>
        {locations.map((x, i) => {
          let url = `${basePath}/${x.url}`;
          return (
            <AnalyticsRoute
              path={url}
              exact
              render={({ history }) => {
                return (
                  <GroupLandingPage
                    onClassSelected={this.onClassSelected(history, basePath)}
                    basePath={basePath}
                    key={i}
                    {...x}
                  />
                );
              }}
            />
          );
        })}
      </React.Fragment>
    );
  }
}

export default GroupPage;
